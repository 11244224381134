.paper {
    width: 210mm;
    height: 290mm;
    padding: 20mm;
    background-color: white;
    border: 1px solid #d3d3d3;
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 100px;
    height: 100%;
}

.textContainer {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    gap: 10px;
}