.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    margin-bottom: 8px;
}

/* Layout para desktop */
@media (min-width: 1011px) {
    .container {
        display: grid;
        grid-template-columns: auto 1fr auto auto;
        grid-template-areas: 
            "time info status actions";
        gap: 16px;
        align-items: center;
        padding: 10px 12px;
    }

    .timeContainer {
        grid-area: time;
        min-width: 45px;
    }

    .info {
        grid-area: info;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-areas:
            "name procedure resource";
        gap: 16px;
        align-items: center;
    }

    .statusSelect {
        grid-area: status;
        width: 120px;
    }

    .containerActions {
        grid-area: actions;
        display: flex;
        gap: 8px;
        align-items: center;
    }
}

/* Layout para mobile */
@media (max-width: 1010px) {
    .container {
        padding: 12px;
        gap: 10px;
    }

    /* Linha 1: Hora - Paciente - Telefone */
    .firstLine {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 12px;
        align-items: center;
    }

    .timeContainer {
        min-width: auto;
    }

    /* Linha 2: Procedimento - Profissional */
    .secondLine {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .procedure {
        flex: 1;
    }

    /* Linha 3: Status */
    .statusSelect {
        width: 100%;
    }

    /* Linha 4: Botões de ação */
    .containerActions {
        width: 100%;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 6px;
    }

    .messageSelect {
        flex: 1;
        width: auto;
    }

    .messageSelectLabel {
        width: 100%;
    }
}

/* Estilos comuns */
.time {
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-text-dark);
}

.name {
    color: var(--color-text-dark);
    font-weight: 500;
    cursor: pointer;
    transition: color 0.2s;
}

.name:hover {
    color: var(--color-main);
}

.phone {
    color: var(--color-text-light);
    font-size: 0.9rem;
    white-space: nowrap;
}

.procedure {
    color: var(--color-text-light);
    font-size: 0.9rem;
    background-color: var(--color-background-light);
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.resource {
    color: var(--color-text-light);
    font-size: 0.85rem;
    background-color: var(--color-main-light);
    padding: 2px 6px;
    border-radius: 4px;
    white-space: nowrap;
}

/* Estilos específicos para os botões de ação */
.messageSelect {
    width: 80px;
}

.messageSelectItem {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px;
}

.messageSelectIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    opacity: 0.7;
}

.messageSelectLabel {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--color-text-light);
    font-size: 0.85rem;
    background-color: var(--color-background-light);
    padding: 2px 6px;
    border-radius: 4px;
    white-space: nowrap;
}

.messageSelectLabelIcon {
    font-size: 14px;
    color: var(--color-text-light);
    opacity: 0.7;
}
