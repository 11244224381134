.form
{
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    gap:20px;
}

.form-group 
{
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;   
    gap: 20px;
}

.form-group-title
{
   font-size: 18px;
   font-weight: bold;
   color: var(--color-text-dark);
}

.form-line 
{
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    flex-wrap: wrap;
}

.form-line > * {
    min-width: 100px;
}

.form-column 
{
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}