.container
{
    display: flex;
    flex-direction: row;
    color: var(--color-secondary);
    border-color: var(--color-secondary);
    border-style: dashed;
    border-width: 1px;
    padding-left: 14px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: var(--border-radius);
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    gap: 10px;
}