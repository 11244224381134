.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  margin: 0;
  color: var(--color-text-dark);
}

.packagesList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.packageCard {
  background: #fff;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-gray-light);
  padding: 20px;
}

.packageHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.packageHeader h3 {
  margin: 0 0 8px 0;
  color: var(--color-text-dark);
}

.status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
}

.active {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4caf50;
}

.inactive {
  background-color: rgba(158, 158, 158, 0.1);
  color: #9e9e9e;
}

.actions {
  display: flex;
  gap: 8px;
}

.description {
  color: var(--color-text-light);
  margin: 0 0 16px 0;
  font-size: 0.875rem;
  line-height: 1.5;
}

.details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  font-size: 0.75rem;
  color: var(--color-text-light);
}

.value {
  font-size: 1rem;
  color: var(--color-text-dark);
  font-weight: 500;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 0;
}

.formHeader {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formRow {
  display: flex;
  gap: 16px;
  align-items: center;
}

.proceduresSection {
  border-top: 1px solid var(--color-gray-light);
  padding-top: 24px;
}

.proceduresSection h4 {
  margin: 0 0 8px 0;
  color: var(--color-text-dark);
  font-size: 1rem;
}

.proceduresDescription {
  margin: 0 0 16px 0;
  color: var(--color-text-light);
  font-size: 0.875rem;
}

.procedureInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.procedureInput span {
  font-size: 0.9rem;
  color: var(--color-text-dark);
  flex: 1;
}

.procedureInputField {
  width: 120px !important;
}

.proceduresList {
  margin-top: 16px;
  border-top: 1px solid var(--color-gray-light);
  padding-top: 16px;
}

.proceduresList h4 {
  margin: 0 0 12px 0;
  color: var(--color-text-dark);
  font-size: 0.875rem;
}

.procedureItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #f5f5f5;
  border-radius: 6px;
  margin-bottom: 8px;
  font-size: 0.875rem;
}

.quantity {
  font-weight: 500;
  color: var(--color-primary);
  background: rgba(var(--color-primary-rgb), 0.1);
  padding: 4px 8px;
  border-radius: 4px;
}

/* Seção de Preço */
.priceSection {
  margin-top: 16px;
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.priceSectionHeader {
  margin-bottom: 12px;
  font-size: 0.875rem;
  color: #666;
  font-weight: 500;
}

.priceInputContainer {
  display: flex;
  gap: 16px;
  align-items: stretch;
}

.priceWrapper {
  flex: 2;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.priceInput {
  flex: 1 !important;
  background-color: #fff !important;
  min-width: 200px !important;
}

.priceInput > div {
  height: 100%;
}

.priceInput input {
  font-size: 1rem !important;
  padding: 12px !important;
  height: 100% !important;
}

.priceInput label {
  font-size: 0.875rem !important;
}

.percentageTag {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  height: fit-content;
  align-self: center;
}

.normalPrice {
  flex: 1;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ddd;
  background-color: #fff;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.normalPriceLabel {
  font-size: 0.75rem;
  color: #666;
  margin-bottom: 4px;
}

.normalPriceValue {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-text-dark);
}

.discount {
  background-color: #e8f5e9;
  color: #2e7d32;
  border-color: #c8e6c9;
}

.increase {
  background-color: #ffebee;
  color: #c62828;
  border-color: #ffcdd2;
}

/* Responsividade */
@media (max-width: 600px) {
  .packagesList {
    grid-template-columns: 1fr;
  }

  .details {
    grid-template-columns: repeat(2, 1fr);
  }

  .actions {
    flex-direction: column;
  }
}