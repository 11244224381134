.default {
    color: var(--color-text-dark);
    cursor: pointer;
    padding: 0px;
}

.actived {
    color: var(--color-secondary);
    cursor: pointer;
    padding: 0px;
}

.actived span{
    font-weight: bold;
}