.score-confirmation {
  text-align: left;
  margin: 2rem 0;
}

.score-confirmation h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.score-status {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.feedback-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.feedback-request {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.comments-input {
  width: 100%;
  min-height: 100px;
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
}

.nps-form-container {
  width: 100%;
  max-width: 800px;
  margin: 0;
  padding: 15px;
  background-color: #fff;
  min-height: fit-content;
  box-sizing: border-box;
}

@media (min-width: 801px) {
  .nps-form-container {
    margin: 0 auto;
    padding: 20px;
  }
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.company-logo {
  margin-bottom: 8px;
}

.company-logo img {
  max-width: 50px;
  height: auto;
  border-radius: 6px;
}

.header-info {
  flex-grow: 1;
}

.header-info h2 {
  color: #333;
  margin-bottom: 8px;
  font-size: 1.5em;
}

.nps-description {
  font-size: 1.0em;
  color: #333;
  margin: 0;
}

.nps-score-selector {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  gap: 5px;
}

.score-button {
  width: 45px;
  height: 45px;
  border: 2px solid #ddd;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  font-size: 1.1em;
}

.score-button:hover {
  border-color: #666;
  transform: scale(1.1);
}

.score-button.selected {
  color: white;
  transform: scale(1.1);
}

.score-button.selected.promoter {
  background-color: #28a745;
  border-color: #28a745;
}

.score-button.selected.neutral {
  background-color: #ffc107;
  border-color: #ffc107;
}

.score-button.selected.detractor {
  background-color: #dc3545;
  border-color: #dc3545;
}

.score-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  color: #666;
  font-size: 0.9em;
  padding: 0 10px;
}

.comments-section {
  margin: 30px 0;
}

.comments-section label {
  display: block;
  margin-bottom: 10px;
  color: #444;
  font-size: 1.1em;
}

.comments-input {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  font-size: 1em;
  line-height: 1.5;
}

.comments-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

.submit-button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 500;
  transition: all 0.3s ease;
}

.submit-button:hover:not(:disabled) {
  background-color: #0056b3;
  transform: translateY(-1px);
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  margin: 15px 0;
  text-align: center;
  padding: 10px;
  background-color: #fff3f3;
  border-radius: 4px;
  border: 1px solid #ffebee;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.thank-you-message {
  text-align: center;
  padding: 10px;
  animation: slideUp 0.6s ease-out;
  max-width: 600px;
  margin: 0 auto;
}

.thank-you-message h2 {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 1.2;
}

.thank-you-message p {
  color: #4a4a4a;
  font-size: 1em;
  line-height: 1.3;
  margin-bottom: 12px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .thank-you-message {
    padding: 15px;
  }

  .thank-you-message h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  .thank-you-message p {
    font-size: 1em;
    line-height: 1.3;
    margin-bottom: 15px;
  }
}

.google-review-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: calc(100% - 32px);
  max-width: 300px;
  padding: 12px 20px;
  background-color: #ffffff;
  color: #4285f4;
  border: 2px solid #4285f4;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  margin: 5px auto 15px;
  position: relative;
  overflow: hidden;
}

.google-review-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #4285f4, #34a853, #fbbc05, #ea4335);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.google-review-button span {
  position: relative;
  z-index: 2;
}

.google-review-button:hover {
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(66, 133, 244, 0.2);
  border-color: transparent;
}

.google-review-button:hover::before {
  opacity: 1;
}

.google-review-button:active {
  transform: translateY(0);
  box-shadow: 0 3px 6px rgba(66, 133, 244, 0.2);
}

/* Ícone do Google */
.google-review-button::after {
  content: 'G';
  font-family: 'Product Sans', Arial, sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  margin-right: 6px;
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) {
  .google-review-button {
    width: auto;
    padding: 16px 32px;
    font-size: 1.1em;
    margin: 20px 0;
    gap: 12px;
  }

  .google-review-button::after {
    font-size: 1.3em;
    margin-right: 8px;
  }
}

.loading-message {
  text-align: center;
  padding: 40px 20px;
  color: #666;
}

.loading-message p {
  font-size: 1.1em;
}

@media (max-width: 600px) {
  .nps-form-container {
    padding: 15px;
    margin: 10px;
  }

  .header-container {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }

  .company-logo img {
    max-width: 120px;
  }

  .header-info h2 {
    font-size: 1.3em;
  }

  .nps-score-selector {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .score-button {
    width: 40px;
    height: 40px;
    font-size: 1em;
  }

  .comments-section label {
    font-size: 1em;
  }
}