.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.containerResume
{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.containerTable
{
    background-color: white;
    border-radius: var(--border-radius);
    padding: 20px;
    margin-bottom: 10px;
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
    overflow: auto;
}
