.radio-selector-content
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 80px;
    align-content: baseline;
}

.radio-selector-label
{
    font-size: 16px;
    font-weight: 700;
    color: var(--color-text-dark);
}

.radio-selector-options-list
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    height: 40px;
    border-radius: 0px;
    border-style: none;
    border-width: 1px;
}

.radio-selector-options-list-error
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    height: 35px;
    border-radius: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-red);
}

