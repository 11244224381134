.templateList {
    padding: 16px;
}

.templateItem {
    margin-bottom: 12px;
    padding: 12px;
    border-radius: 8px;
    transition: background-color 0.2s;
    border: 1px solid #e0e0e0;
}

.templateItem:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.templateItemContent {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.templateNumber {
    background-color: #1976d2;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    flex-shrink: 0;
}

.templatePreview {
    color: #666;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}