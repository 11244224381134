.packageCard {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.packageHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.packageHeader h4 {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
}

.packageStatus {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  margin-top: 4px;
  background-color: #e0e0e0;
  color: #666;
}

.packageStatus.active {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.packagePrice {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1976d2;
}

.packageDescription {
  margin: 8px 0;
  font-size: 0.9rem;
  color: #666;
  flex-grow: 1;
}

.packageDetails {
  margin-top: 12px;
  border-top: 1px solid #eee;
  padding-top: 12px;
}

.detailLabel {
  display: block;
  font-size: 0.85rem;
  color: #666;
  margin-bottom: 4px;
}

.detailValue {
  font-size: 0.9rem;
  color: #333;
}

.proceduresList {
  margin-bottom: 12px;
}

.procedureItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #333;
  padding: 4px 0;
}

.packageButton {
  margin-top: 16px !important;
}