.container{
    display: flex;
    flex-direction: row;
    gap:20px;
    align-items: center;
}

.containerTexts
{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.containerTexts h2{
    font-size: 1.25rem;
}

.containerTexts p{
    color: #575757;
}