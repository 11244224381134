.day-appointment {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    width: 100%;
    height: 100%;
    gap: 0;
    position: relative;
    margin: 0;
    padding: 0;
}

.day-appointment-today {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    gap: 0;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.day-appointment-today-disabled {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.day-appointment-today-disabled:only-child {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.day-appointment-disabled:only-child {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.day-appointment-disabled {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.day-appointments-container {
    display: flex;
    flex-direction: column;
    gap: 1px;
    height: calc(100vh - 230px); /* Ajustado para considerar o header */
    padding: 0;
    margin: 0;
    position: relative;
}

.day-appointments-container > div {
    height: calc((100% - 11px) / 12); /* Altura total menos gaps */
    margin: 0;
    padding: 0;
}

/* Estilo para o cabeçalho do dia */
.day-header {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    font-size: 13px;
    margin: 0 0 16px 0;
    padding: 0;
}

@media (max-width: 768px) {
    .day-appointment {
        min-width: 120px;
        padding: 0;
        margin: 0;
        height: 100%;
    }

    .day-header {
        font-size: 12px;
        height: 35px;
        margin: 0;
        padding: 0;
    }

    .day-appointments-container {
        height: calc(100vh - 230px);
        padding: 0;
        gap: 1px;
        margin: 0;
        position: relative;
    }

    .day-appointments-container > div {
        height: calc((100% - 11px) / 12);
        margin: 0;
        padding: 0;
    }
}