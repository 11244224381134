.boxRoundedContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 500px;
    min-height: 450px;
    max-height: 450px;
    border-radius: var(--border-radius);
    background-color: white;
    padding: 40px;
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
    gap: 30px;
}


.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        grid-gap: 20px;
}

@media (max-width: 1010px) {
    .boxRoundedContainer {
        width: 100%;
        min-width: 90vw;
        padding: 30px;
        gap: 10px;
    }
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit);
        grid-gap: 20px;
    }
}
