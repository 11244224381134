.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}

.containerArea {
    flex: 1;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    overflow: auto;
}