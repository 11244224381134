.patients-header-container
{
    display: flex;
    flex-direction: column;
    flex:1;
    gap:10px;
}

.patients-header
{
    display: flex;
    flex:1;
    border-radius: var(--border-radius);
    height: fit-content;
    padding-block: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.patients-subheader
{
    display: flex;
    flex-direction: row;
    height: 40px;
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    align-items: center;
    font-size: 14px;
    font-weight: bold;
}