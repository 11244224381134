.create-appointment-popup-content {
    padding-right: 10px;
}

.appointment-header {
    display: flex;
    align-items: stretch;
    gap: 12px;
    margin-bottom: 24px;
}

.appointment-datetime-box {
    flex: 1;
    background: var(--white);
    border-radius: 6px;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid var(--border);
}

.appointment-datetime-icon {
    color: var(--main);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--mainAlpha01);
    padding: 8px;
    border-radius: 4px;
}

.appointment-datetime-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.appointment-datetime-label {
    font-size: 12px;
    color: var(--textLight);
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.appointment-datetime-value {
    font-size: 16px;
    font-weight: 500;
    color: var(--textDark);
}

.status-wrapper {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    align-items: stretch;
}

.status-section {
    background: var(--white);
    border-left: 4px solid var(--main);
    padding: 12px;
    flex: 1;
    box-shadow: 0 1px 2px var(--shadow);
    margin-bottom: 0;
    min-height: 56px;
    display: flex;
    align-items: center;
}

.status-section .MuiSelect-select {
    font-size: 14px;
    min-width: 180px;
    color: var(--textDark);
}

.status-actions {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
    background: var(--white);
    border-radius: 6px;
    padding: 12px;
    border: 1px solid var(--border);
    min-height: 56px;
}

.status-actions .MuiButton-root {
    height: 100%;
    min-width: 120px;
}

/* Ajustes responsivos */
@media screen and (max-width: 768px) {
    .status-wrapper {
        flex-direction: column;
        gap: 12px;
    }

    .status-actions {
        padding: 8px;
        justify-content: center;
    }

    .status-actions .MuiButton-root {
        flex: 1;
        min-width: 140px;
        max-width: calc(50% - 4px);
    }

    .appointment-main-info {
        flex-wrap: wrap;
    }

    .appointment-main-info .MuiFormControl-root {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 8px;
    }

    .appointment-main-info button {
        flex: 1;
        max-width: calc(50% - 6px);
    }
}

@media screen and (max-width: 480px) {
    .status-actions .MuiButton-root {
        max-width: 100%;
    }

    .appointment-main-info button {
        max-width: 100%;
    }
}

.appointment-section {
    background: var(--white);
    border-radius: 6px;
    padding: 16px;
    margin-bottom: 24px;
    border: 1px solid var(--border);
}

.appointment-section:last-child {
    margin-bottom: 0;
}

.appointment-section-title {
    font-size: 13px;
    color: var(--textDark);
    margin-bottom: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.appointment-main-info {
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 56px;
    width: 100%;
}

.appointment-main-info .MuiFormControl-root {
    flex: 1;
    min-width: 200px;
    max-width: calc(100% - 120px); /* Espaço para os botões */
}

/* Garante que o input mantenha sua altura */
.appointment-main-info .MuiInputBase-root {
    height: 56px;
    min-height: 56px;
}

/* Previne que o texto seja cortado */
.appointment-main-info .MuiInputBase-input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.appointment-actions {
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
}

.appointment-actions > * {
    flex: none;
}

.appointment-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 16px;
}

.appointment-details .MuiFormControl-root {
    margin: 0;
    height: 100%;
}

.appointment-details .MuiInputLabel-root {
    transform: translate(14px, 14px) scale(1);
}

.appointment-details .MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
}

.appointment-details .MuiOutlinedInput-root {
    height: 100%;
}

.appointment-price-duration {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 24px;
}

.appointment-price-duration .MuiFormControl-root {
    margin: 0;
    height: 100%;
}

.whatsapp-reminder {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 24px;
    padding: 12px;
    background: var(--white);
    border-radius: 6px;
    border: 1px solid var(--border);
}

.whatsapp-reminder-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--mainAlpha01);
    padding: 8px;
    border-radius: 4px;
    color: var(--main);
}

.whatsapp-reminder-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.whatsapp-reminder-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--textDark);
}

.whatsapp-reminder-description {
    font-size: 13px;
    color: var(--textLight);
}

.whatsapp-reminder-switch {
    margin-left: auto;
}

/* Estilos para os campos de formulário */
.MuiFormControl-root {
    width: 100%;
}

.MuiInputLabel-root {
    font-size: 13px;
    color: var(--textDark) !important;
}

.MuiSelect-select {
    font-size: 13px;
    color: var(--textDark);
}

.MuiOutlinedInput-root {
    background: var(--white);
}

/* Ajustes para botões mais compactos */
.MuiButton-root {
    padding: 6px 12px;
    min-width: auto;
}

.MuiButton-startIcon {
    margin-right: 4px;
}

/* Ajustes de cores para inputs */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--main) !important;
}

.MuiInputLabel-root.Mui-focused {
    color: var(--main) !important;
}

/* Ajuste de cor para texto dos inputs */
.MuiInputBase-input {
    color: var(--textDark) !important;
}

/* Cor do texto desabilitado mais suave mas legível */
.Mui-disabled {
    color: var(--textLight) !important;
}

/* Ajustes para alinhamento dos campos */
.MuiFormControl-root {
    display: flex;
    flex-direction: column;
}

.MuiInputBase-root {
    height: 56px;
}

.MuiInputBase-input {
    height: auto !important;
    padding: 16.5px 14px !important;
}

.MuiSelect-select {
    display: flex !important;
    align-items: center !important;
}

:root {
    --white: #fff;
    --main: #206afa;
    --mainAlpha01: rgba(32, 106, 250, 0.1);
    --textDark: #273644;
    --textLight: #535353;
    --border: rgba(0, 0, 0, 0.1);
    --shadow: rgba(0, 0, 0, 0.1);
    --green: #B9C4B0;
    --greenLight: #E2FCD6;
}
