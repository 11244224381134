.appointment-header-container
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 30px;
}

.appointment-header-item
{
    display: flex;
    flex: 1;
    gap: 10px;
    align-items: center;
    border-radius: var(--border-radius);
    background-color: #DDECFD;
    padding-inline: 20px;
    padding-block: 10px;
    text-wrap: nowrap;
}

.appointment-header-icon
{
    font-size: 14px;
    color: #1a77da;
}