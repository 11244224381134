.progress-circle {
    transform: rotate(-90deg);
  }
  
  .total-stroke {
    fill: none;   
  }
  
  .current-stroke {
    fill: none;
    transition: stroke-dashoffset 0.3s ease-in-out;
  }
  