.mainContainer
{
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    gap:30px;
}

.itemContainer
{
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 350px;
    gap:20px;
}