.containerTable {
    background-color: white;
    border-radius: var(--border-radius);
    padding: 10px; /* Menor padding */
    margin-bottom: 10px;
    box-shadow: 0 0 15px rgba(196, 196, 196, 0.2); /* Leve redução no shadow */
}

.containerColumns {
    display: flex;
    gap: 6px;
    flex: 1;
}

.column {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: var(--border-radius);
    padding: 20px; /* Menor padding */
    margin-bottom: 10px;
    box-shadow: 0 0 15px rgba(196, 196, 196, 0.2); /* Leve redução no shadow */
    gap: 10px; /* Menor gap entre cards */
}

.leadCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 8px;
    min-height: 140px;
    box-sizing: border-box;
    transition: all 0.2s ease;
    background-color: #fff;
}

.leadCard:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
}

.leadMainInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.leadNameDate {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.leadName {
    font-size: 1.1em;
    font-weight: 600;
    color: #2c3e50;
}

.leadDate {
    font-size: 0.8em;
    color: #7f8c8d;
}

.leadContact {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.leadMainInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
}

.leadInfoColumn {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.phoneNumber {
    font-size: 0.85em;
    font-weight: 500;
    color: #2c3e50;
}

.leadName {
    font-size: 0.85em;
    font-weight: 600;
    color: #2c3e50;
}

.whatsappButton {
    color: var(--color-text-dark);
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.2s ease;
    font-size: 38px;
    margin-right: 4px;
}

.whatsappButton:hover {
    color: #25D366;
    background-color: rgba(37, 211, 102, 0.1);
}

.leadFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.leadTags {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
}

.leadTag {
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.8em;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 0.6em;
}

.statusButtons {
    display: flex;
    gap: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 2px;
}

.statusButton {
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    color: #6c757d;
}

.statusButton:hover {
    background-color: var(--color-secondary);
    color: #ffffff;
}

.statusButton.disabled {
    cursor: not-allowed;
    opacity: 0.1;
}

.statusButton.disabled:hover {
    background-color: transparent;
    color: #6c757d;
}

.hotLead {
    background-color: rgba(255, 99, 71, 0.1); /* Tom vermelho */
    border: 1px solid rgba(255, 99, 71, 0.3);
}

.warmLead {
    background-color: rgba(255, 165, 0, 0.1); /* Tom laranja */
    border: 1px solid rgba(255, 165, 0, 0.3);
}

.coldLead {
    background-color: rgba(173, 216, 230, 0.1); /* Tom azul */
    border: 1px solid rgba(173, 216, 230, 0.3);
}

.successLead {
    background-color: rgba(182, 243, 182, 0.1); /* Tom verde */
    border: 0px solid rgba(50, 205, 50, 0.3);
}


.failureLead {
    background-color: rgba(252, 205, 211, 0.5); /* Tom vermelho */
    border: 0px solid rgba(255, 0, 0, 0.3);
}

.failureInactiveLead
{
    background-color: rgba(252, 205, 211, 0.1); /* Tom vermelho */
    border: 0px solid rgba(255, 0, 0, 0.3);
}