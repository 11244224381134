.container
{
    display: flex;
    flex:1;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    border-radius: var(--border-radius);
    background-color: var(--color-white);
}

.containerHeader
{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
}

.containerItems
{
    display: flex;
    flex-direction: column;
    gap: 20px;
}