.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    border-radius: var(--border-radius);
    background-color: white;
    padding-inline: 20px;
    padding-block: 10px;
    height: fit-content;
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}

.containerTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.containerItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}