.header {
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  background-color: #ffffff;
  padding-left: 20px;
  padding-right: 10px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  gap: 10px;
  border-radius: var(--border-radius);
  box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}

@media screen and (max-width: 1010px) {
  .header {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 20px;
  }
}