.sidebar-item {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  list-style: none;
  align-items: center;
  padding: 8px 12px;
  border-radius: var(--border-radius);
  color: var(--color-text);
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
}

.sidebar-item:hover {
  background-color: var(--color-background-hover);
}

.sidebar-item-actived {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  list-style: none;
  align-items: center;
  background-color: var(--color-main);
  padding: 8px 12px;
  border-radius: var(--border-radius);
  color: white;
  text-decoration: none;
  font-weight: 500;
}

.sidebar-item-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sidebar-label,
.sidebar-label-active,
.sidebar-label-hover {
  margin-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.3s;
  color: inherit;
}

.sidebar-label {
  color: var(--color-text);
  opacity: 0.9;
}

.sidebar-label-active {
  color: inherit;
  font-weight: 500;
  opacity: 1;
}

.sidebar-label-hover {
  color: var(--color-main);
  opacity: 1;
}

.sidebar-item-icon {
  color: var(--color-text);
  min-width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
  opacity: 0.7;
}

.sidebar-item:hover .sidebar-item-icon {
  color: var(--color-main);
  opacity: 1;
}

.sidebar-item-icon-actived {
  color: inherit;
  min-width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}