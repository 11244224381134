/*@import url('https://fonts.googleapis.com/css?family=Google+Sans:100,300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Segoe+UI:100,300,400,500,700&display=swap');*/
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');


:root {
  --font: "Roboto", Arial, Helvetica, sans-serif;
  --font-weight-title: 500;
  --font-weight-description: 400;
  --font-weight-p: 400;
  --font-weight-menu: 400;
  --desktop-screen-size: 600px;
  --padding-area-desktop: 100px;
  --padding-area-mobile: 40px;
  --border-radius:8px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.app-principal {
  display: flex;
  flex:1;
  flex-direction: row;
}

.app-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
  margin-left: 80px;
}

.page-content {
  flex: 1;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

textarea {
  overflow-y: auto !important;
}

/* Correção para TextField multiline do Material-UI */
.MuiTextField-root .MuiInputBase-multiline {
  align-items: flex-start !important;
  padding-top: 0 !important;
  min-height: auto !important;
}

.MuiTextField-root .MuiInputBase-multiline .MuiInputBase-input {
  padding-top: 8px !important;
  box-sizing: content-box !important;
  height: auto !important;
}

.MuiTextField-root .MuiInputLabel-root {
  transform-origin: top left !important;
}

.MuiTextField-root .MuiOutlinedInput-notchedOutline {
  height: 100% !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  height: auto !important;
  align-items: stretch !important;
}

/* bg f4f4f6  ou f6f7f9*/
html,
body {
  max-width: 100vw;
  background-color:#f6f7f9;
  margin: 0;
  padding: 0;
  font-family: var(--font);
  color: #0c0c0c;
}

body::-webkit-scrollbar {
  width: 8px;
  /* Largura da barra de rolagem reduzida */
}

body::-webkit-scrollbar-track {
  background-color: #f6f7f9;
  /* Cor da trilha da barra de rolagem ajustada para combinar com o background */
}

body::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  /* Cor do botão de rolagem mais suave */
  border-radius: 4px;
  /* Borda arredondada do botão de rolagem */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
  /* Cor mais escura ao passar o mouse */
}


a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 2rem;
  /* Aproximadamente 32px */
  font-weight: 700;
}

h2 {
  font-size: 1.5rem;
  /* Aproximadamente 24px */
  font-weight: 700;
}

h3 {
  font-size: 1.25rem;
  /* Aproximadamente 20px */
  font-weight: 700;
}

h4 {
  font-size: 1rem;
  /* Aproximadamente 16px */
  font-weight: 700;
}

h5 {
  font-size: 0.875rem;
  /* Aproximadamente 14px */
  font-weight: 700;
}

p {
  font-size: 0.90rem;
  /* Aproximadamente 16px */
  font-weight: 400;
  /* Aproximadamente 24px */
}

span {
  font-size: 0.90rem;
  font-weight: 400;
}

thead th{
  font-size: 1rem;
  font-weight: 500;
  height: 40px;
}

td {
  font-size: 0.90rem;
  padding: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  padding: 6px;
  text-align: left;
}

th:first-child {
  padding-left: 20px;
}

td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 20px;
}

td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}

tr:nth-child(even) {
  background-color: #eef2f8;/*f9f9f9*/
}

@media only screen and (max-width: 1010px) {
  
  .app-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 768px) {

  .app-principal {
    flex: 1;
    display: flex;
    flex-direction: row;
  }

  .app-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  .page-content {
    flex: 1;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.5rem;
      /* Aproximadamente 24px */
    }

    h2 {
      font-size: 1.25rem;
      /* Aproximadamente 20px */
    }

    h3 {
      font-size: 1.125rem;
      /* Aproximadamente 18px */
    }

    h4 {
      font-size: 1rem;
      /* Aproximadamente 16px */
    }

    h5 {
      font-size: 0.875rem;
      /* Aproximadamente 14px */
    }

    p,
    span {
      font-size: 0.85rem;
      line-height: 0.95rem;
    }

    td {
      font-size: 0.80rem;
      line-height: 1rem;
    }
  }
}