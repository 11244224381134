.mainContainer {
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  transition: 0.2s ease-out;
  position: relative;
  background-color: transparent;
  height: 100%;
  min-width: 0;
  width: 100%;
  padding: 0;
}

.mainContainerOver {
  display: flex;
  flex:1;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  transition: 0.2s ease-out;
  position: relative;
  background-color: transparent;
  min-width: 100px;
  padding-left: 20px;
  z-index: 100;
  transition: 0.2s ease-out;
  height: 100%;
}

.mainContainerBusy {
  display: flex;
  flex: 1;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  transition: 0.2s ease-out;
  position: relative;
  background-color: transparent;
  min-width: 100px;
  height: 100%;
}

.date {
  font-size: 12px;
  font-weight: 500;
  color : var(--color-text-light)
}

.dateActived {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-secondary)
}

.appointmentsContainer {
  display: flex;
  flex: 1 1 0;
  width: 100%;
  height: 100%;
  gap: 4px;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-width: 0;
  overflow: hidden;
  padding: 0;
}

.currentTimeLine {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  z-index: 10;
  border-bottom: 2px dashed var(--color-secondary);
}

.appointmentItem {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 0;
  padding: 0 8px;
  gap: 4px;
  align-items: center;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  min-width: 0;
}

/* Estilo específico para slots disponíveis */
.appointmentItem[data-status="available"] {
  background-color: rgba(240, 242, 245, 0.3);
  border: 1px dashed rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
  height: 100%;
  width: 100%;
  flex: 1;
}

.appointmentItem[data-status="available"]:hover {
  background-color: rgba(240, 242, 245, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-content: baseline;
}

.label {
  font-size: 12px;
  font-weight: 400;
  min-height: 14px;
  max-height: 28px;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.procedure {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .mainContainer {
    height: 100%;
    padding: 0;
  }

  .date, .dateActived {
    display: none;
  }

  .label {
    font-size: 12px;
    min-height: 14px;
    max-height: 28px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .appointmentItem {
    padding: 0 6px;
    gap: 3px;
    height: 100%;
  }

  .iconsContainer {
    gap: 4px;
  }

  .iconsContainer svg {
    width: 14px;
    height: 14px;
  }

  .procedure {
    font-size: 11px;
  }
}
