.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.containerStats {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.containerAppointmentsList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.titleContainer
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}