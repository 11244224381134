.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.contentWrapper {
    display: flex;
    gap: 20px;
    height: 100%;
}

.sidebarContent {
    width: 300px;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
    padding: 20px;
    height: fit-content;
    flex-shrink: 0;
}

.mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 0; /* Evita que o conteúdo ultrapasse o container */
}

.actions {
    display: flex;
    gap: 15px;
    align-items: center;
}

.filters {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    background-color: white;
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}

.filterTitle {
    margin: 0;
    color: var(--text-light);
    font-size: 16px;
    font-weight: 500;
}

.additionalFilters {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}

.tableContainer {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow: hidden;
    margin-bottom: 20px;
}

.sidebarTitle {
    margin: 0 0 20px 0;
    color: var(--text-light);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
}

.recurringItem {
    padding: 15px;
    border-radius: var(--border-radius);
    background-color: #f5f5f5;
    margin-bottom: 10px;
}

.recurringItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.recurringItemTitle {
    font-weight: 500;
    color: var(--text);
}

.recurringItemAmount {
    font-weight: 500;
}

.recurringItemInfo {
    font-size: 0.875rem;
    color: var(--text-light);
}

.recurringItemActions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 8px;
}

.income {
    color: var(--main) !important;
}

.expense {
    color: var(--secondary) !important;
}

.typeBadge {
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 0.875rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
}

.typeBadgeIncome {
    composes: typeBadge;
    background-color: #e3f2fd;
    color: var(--main);
}

.typeBadgeExpense {
    composes: typeBadge;
    background-color: #fce4ec;
    color: var(--secondary);
}

.formContainer {
    max-width: 600px;
    margin: 0 auto;
}

.formGroup {
    margin-bottom: 20px;
}

.formGroup label {
    display: block;
    margin-bottom: 8px;
    color: var(--text-light);
}

.formActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.recurrenceFields {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--gray-light);
}

.noRecurringTransactions {
    text-align: center;
    color: var(--text-light);
    padding: 20px 0;
}

@media (max-width: 1200px) {
    .contentWrapper {
        flex-direction: column;
    }

    .sidebarContent {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .filters {
        flex-direction: column;
        gap: 15px;
    }

    .additionalFilters {
        flex-direction: column;
        align-items: stretch;
        padding: 15px;
    }

    .header {
        flex-direction: column;
        gap: 15px;
    }

    .actions {
        width: 100%;
        justify-content: flex-end;
    }
}