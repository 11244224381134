.container
{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
}

.containerWhiteBox
{
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: var(--border-radius);
    background-color: var(--color-white);
    padding: 30px;
    min-width: 550px;
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}

.containerAppointment
{
    display: flex;
    flex-direction: row;
    flex:1;
    gap: 20px;
    justify-content: space-between;
    border-radius: var(--border-radius);
    background-color: var(--color-white);
    padding: 30px;
}
.containerPatient
{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.containerAssessmentEvolution
{
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    border-radius: var(--border-radius);
    background-color: var(--color-white);
    padding: 30px;
    align-items: center;
}

.containerAssessmentEvolution div
{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex:1;
    gap:14px
}

@media (max-width: 1010px) {
    .containerPatient {
        display: flex;
        flex-direction: column;
        flex:1;
        gap: 20px;
    }

    .containerWhiteBox
    {
        width: 100%;
        min-width: 100%;
    }
}

