.container
{
    display: flex;
    flex:1;
    align-content: flex-start;
}

.containerDate
{
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
}

.date
{
    font-weight: bold;
    color: var(--color-gray-dark);
}

.containerInfo {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;    
    margin-left: 20px;
    background-color: #f9f9f9;
    border-radius: var(--border-radius);
}

.containerInfoItem
{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 20px;

}

.containerInfoItem span
{
}