.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.patientInfosGroup
{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.patientInfosTitle
{
    margin-bottom: 12px;
}
.patientInfosItem
{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--color-gray-light);
    border-radius: 0px;
    padding-block: 14px;
}

.patientInfosItemKey
{
    width: 120px;
    color:  var(--color-text-dark);
}

.patientInfosItemValue
{
    color:  var(--color-text-dark);
}

.containerEditButton
{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}