.container
{
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex:1;
    position: relative;
}

.editButton {
    position: absolute;
    top: 10px;
    right: 10px;
}
.editBtn {
    background-color: var(--primary-color);
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.editBtn:hover {
    opacity: 0.9;
}

.list
{
    display: flex;
    flex-direction: column;
    flex:1;
}

.item
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: var(--border-radius);
}

.item:nth-child(even)
{
    background-color: #f9f9f9;
}

.question
{
}

.answer
{
}

.obs
{
    font-size: 14px;
}