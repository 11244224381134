.userDisplay
{
    display: flex;
    gap: 15px;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: var(--color-text-dark);
}

.userDisplayInfos
{
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.userDisplayImg
{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
