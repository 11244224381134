.container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: var(--color-white);
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 3000;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
}

.close {
    font-size: 24px;
    cursor: pointer;
    align-self: flex-end;
    color: var(--color-text);
    padding: 5px;
    transition: color 0.2s;
}

.close:hover {
    color: var(--color-primary);
}