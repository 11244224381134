.formContainer
{
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.itemContainer
{
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
}
.questionContainer
{
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.question {
}