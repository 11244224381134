.icon
{
    cursor: pointer;
}

.box
{
    background-color: white;
    padding: 10px;
}

.description
{
    font-size: 10px;
}