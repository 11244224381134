.container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: var(--border-radius);
    color: white;
    background-color: var(--color-main);
}