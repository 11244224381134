.search-component
{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45px;
    background-color: white;
    border-radius: var(--border-radius);
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    gap: 10px;
}

.search-component input
{    
    width: 100%;
    background-color: white;
    color: var(--color-text-light);
    border: none;
    outline: none;
}

.search-component input:focus
{
    width: 100%;
    background-color: white;
    border: none;
    outline: none;
    color: var(--color-text-dark);

}