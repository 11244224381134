.mainContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  gap: 20px;
  flex-wrap: wrap-reverse;
  max-width: 100vw;
  overflow: hidden;
}

.calendar {
  display: flex;
  flex-direction: column;
  width: calc(100vw - (410px));
  height: 100%;
  transition: width 0.3s ease;
}

.calendar > div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 100%;
}

.calendar > div > div {
  flex: 1;
  min-width: 0;
}

.calendarExpanded {
  width: calc(100vw - (210px));
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  align-items: 'flex-start';
  width: 250px;
  gap: 16px;
  margin-right: 0px;
  position: relative;
  transition: width 0.3s ease, transform 0.3s ease;
  background-color: white;
  padding: 16px;
  padding-left: 0px;
  border-radius: 0;
  border-right: 1px solid #e0e0e0;
}

.filtersContainerCollapsed {
  width: 30px;
  overflow: visible;
}

.filtersContainerCollapsed > *:not(.toggleFiltersButton):not(.collapsedIcons) {
  opacity: 0;
}

.collapsedIcons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 100px;
  align-items: center;
}

.iconButton {
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  transition: all 0.2s ease;
}

.iconButton:hover {
  background-color: #f5f5f5;
}

.collapsedIcon {
  width: 20px;
  height: 20px;
  color: #1976d2;
}

.toggleFiltersButton {
  position: absolute;
  right: -12px;
  top: 34px;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 1;
  transition: background-color 0.2s ease;
}

.toggleFiltersButton:hover {
  background-color: #fafafa;
}

.toggleFiltersButton svg {
  width: 16px;
  height: 16px;
  color: #999;
}

.containerCalendar {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100%;
}

.loadingOverlayFadeIn {
  opacity: 1;
  transition: 0s linear;
}

.loadingOverlayFadeOut {
  opacity: 0;
  transition: 0.2s linear;
  pointer-events: none;
}

.calendarContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}

/* Containers com gaps */
.columnsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 3px;
  height: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
}

.innerContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .filtersContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    align-items: 'flex-start';
  }

  .calendar {
    width: calc(100vw - 95px); /* 50px TimeColumn + 6px gap + 19px scrollbar + 20px extra */
    margin: 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-padding-left: 0;
    scroll-padding-right: 0;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  .calendar > div {
    gap: 3px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: stretch;
    position: relative;
    width: max-content;
  }

  .calendar > div > div {
    flex: 0 0 120px;
    scroll-snap-align: start;
    margin: 0;
    padding: 0;
  }

  .toggleFiltersButton {
    display: none;
  }

  .filtersContainerCollapsed {
    width: 100%;
  }

  .filtersContainerCollapsed > *:not(.toggleFiltersButton) {
    opacity: 1;
  }

  .calendarContainer {
    display: flex;
    position: relative;
    margin: 0;
    padding: 0;
    width: calc(100vw - 95px);
    overflow: hidden;
  }

  .contentContainer {
    gap: 5px;
    margin: 0;
    padding: 0;
    width: calc(100vw - 95px);
    overflow: hidden;
  }

  .innerContainer {
    gap: 15px;
    margin: 0;
    padding: 0;
    width: calc(100vw - 95px);
    overflow: hidden;
  }

  .headerContainer {
    gap: 5px;
    margin: 0;
    padding: 0;
  }
}