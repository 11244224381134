.mainContainer
{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.containerAreas
{
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
}

.containerArea
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 40px;
    border-radius: var(--border-radius);
    background-color: var(--color-white);
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}