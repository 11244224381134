.inputfield-container
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputfield-label
{
    display: flex;
    font-size: 14px;
    color: var(--color-text-dark);
    gap: 5px;
}

.inputfield-container input
{    
    background-color: white;
    color: var(--color-text-dark);
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
    padding: 12px;
    font-size: 14px;
}

.inputfield-container input:disabled {
    background-color: var(--color-white);
    color: var(--color-text-dark);
    opacity: 1;
  }

.inputfield-container input:focus
{
    color: var(--color-text-dark);
    border-color:  var(--color-main);
    outline: none;
}

.inputfield-container-error
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputfield-container-error input
{    
    background-color: var(--color-red-light);
    color: var(--color-red);
    border-radius: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-red);
    padding: 10px;
}

