.container
{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
}

.containerAllInfo
{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.containerInfo
{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 6px;
}

.imageContainer
{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--color-background-light);
    display: flex;
    align-items: center;
    justify-content: center;
}

.name
{
    color: var(--color-text-dark);
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.gender
{
    color: var(--color-text-dark);
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.birthday
{
    color: var(--color-text-dark);
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .imageContainer
    {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .containerInfo
    {
        gap: 0px;
    }

    .containerAllInfo
    {
        gap: 2px;
    }
}