.timeColumn {
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 2;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 230px);
  width: 50px;
  flex-shrink: 0;
  position: sticky;
  left: 0;
  padding: 0;
  margin: 51px 0 0 0; /* 35px header + 16px margin */
}

.timeColumnHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 35px;
}

.timeSlotContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1px;
  margin: 0;
  padding: 0;
}

.timeSlot {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc((100vh - 230px - 11px) / 12); /* Mesma altura dos slots de agendamento */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.time {
  font-size: 10px;
  color: var(--color-text-light);
  font-weight: 500;
  text-align: center;
}

@media (max-width: 768px) {
  .timeColumn {
    height: calc(100vh - 230px);
    padding: 0;
    margin: 51px 0 0 0; /* 35px header + 16px margin */
  }

  .timeColumnHeader {
    height: 35px;
  }

  .timeSlot {
    height: calc((100vh - 230px - 11px) / 12); /* Mesma altura dos slots de agendamento */
    margin: 0;
    padding: 0;
  }
  
  .time {
    font-size: 9px;
  }
}
