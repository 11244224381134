.option-item-container
{
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 12px;
    color:  #898f96;
    cursor: pointer;
    justify-content: center;
    transition: 0.2s;
}

.option-item-container-actived
{
    display: flex;
    flex-direction: row;
    background-color: #f7f7f7;
    gap:10px;   
    border-radius: 8px;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 12px;
    justify-content: center;
    color:  #2a3b50;
    transition: 0.2s;
}

