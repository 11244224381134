.radio-item
{
    display: flex;
    flex-direction: row;
    color: var(--color-text-light);
    font-size: 18px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.radio-item-actived
{
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: var(--color-main);
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 10px;
}