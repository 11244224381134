.containerAppointmentsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.walletContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.statsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: var(--border-radius);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray-light);
  min-width: 200px;
}

.containerValues {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.icon {
  font-size: 26px;
  color: var(--color-primary);
}

.label {
  font-size: 16px;
  color: var(--color-text-dark);
}

.value {
  font-size: 20px;
  color: var(--color-text-dark);
}

.creditsList, .transactionsList {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 16px;
}

.creditsList h4, .transactionsList h4 {
  margin: 0 0 16px 0;
  color: #333;
}

.creditsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 12px;
  width: 100%;
}

.creditItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 6px;
  background: #fafafa;
  border: 1px solid #e0e0e0;
  flex: 1;
}

.creditBadge {
  background-color: #206afa;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  min-width: 40px;
  text-align: center;
}

.creditName {
  flex: 1;
  font-weight: 500;
  color: #333;
}

.creditDate {
  font-size: 0.7rem;
  color: #666;
  min-width: 140px;
  text-align: right;
}

.expiringCredit {
  background-color: rgba(255, 152, 0, 0.05);
  border-color: rgba(255, 152, 0, 0.3);
}

.expiringCredit .creditDate {
  color: #f57c00;
  font-weight: 500;
}

.transactionsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.transactionItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 6px;
  background: #fafafa;
  border: 1px solid #e0e0e0;
}

.transactionDescription {
  flex: 1;
  font-weight: 500;
  color: #333;
}

.transactionDate {
  font-size: 0.875rem;
  color: #666;
  min-width: 90px;
  text-align: right;
}

.transactionValue {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #333;
}

.transactionBadge {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 40px;
  text-align: center;
}

.positive {
  background-color: #4caf50;
  color: white;
}

.negative {
  background-color: #f44336;
  color: white;
}

/* Estilos para o Dialog de Pacotes */
.packageCard {
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.packageHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.packageStatus {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  margin-top: 4px;
  background-color: #e0e0e0;
  color: #666;
}

.active {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4caf50;
}

.packagePrice {
  color: #2196f3;
  font-weight: 600;
  font-size: 1.25rem;
}

.packageDescription {
  color: #666;
  margin-bottom: 16px;
  font-size: 0.875rem;
}

.packageDetails {
  display: flex;
  gap: 24px;
  margin-bottom: 16px;
}

.detailLabel {
  display: block;
  color: #666;
  font-size: 0.75rem;
  margin-bottom: 4px;
}

.detailValue {
  display: block;
  color: #333;
  font-weight: 500;
}

.packageButton {
  margin-top: auto !important;
}