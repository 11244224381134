.container
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.image {
    width: 100px;
    height: auto;
}

.infos{
    display: flex;
    flex-direction: column;
}