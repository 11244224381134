.container
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
}

.containerTitle
{
    display: flex;
    flex:1;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.painList
{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
}

