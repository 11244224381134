.background {
    position: fixed;
    /* Fixado na tela */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(197, 197, 197, 0.9);
    z-index: 1000;
    width: 100vw;
    height: 100vh;
}

.box {
    position: absolute;
    display: flex;
    z-index: 1001;
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    width: 95%;
    min-width: 400px;
    max-height: 90vh;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-inline: 30px;
    padding-block: 30px;
    max-width: 1000px;
    gap: 20px;
}

.header {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    color: var(--color-text-dark);
}

.title {
}

.close {
    cursor: pointer;
}

.content {
   
    box-sizing: border-box;
    overflow: auto;
}

.footer {
    display: flex;
    padding: 0px;
    flex-direction: row;
    justify-content: flex-end;
}

.buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}


.content::-webkit-scrollbar {
    width: 6px; /* Largura da barra de rolagem */
    height: 6px; /* Altura da barra de rolagem */
}

.content::-webkit-scrollbar-thumb {
    background-color: var(--color-main); /* Cor do botão de rolagem (scroll) */
    border-radius: 10px; /* Borda arredondada do botão de rolagem */
}

.content::-webkit-scrollbar-track {
    background-color: var(--color-main-light); /* Cor da trilha da barra de rolagem */
}

@media (max-width: 1010px) {
    .box {
        width: 95%;
        min-width: unset;
    }
}