.container
{
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    border-width: 1px;
    border-color: #d3d3d3;
    border-style: solid;
}

.info
{
    display: flex;
    flex-direction: row;
    gap: 10px;
}