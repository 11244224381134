.container
{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
}

.containerHeader
{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
}

.containerAllInfo
{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.containerArchive
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
}