.container{
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 10px 0;
    border-bottom: 1px solid var(--color-gray-light);
    justify-content: space-between;
    flex-wrap: wrap;
}

.infos
{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}

.paid
{
    background-color: var(--color-green-light);
    color: var(--color-text-dark);
    padding: 10px 20px;
    border-radius: var(--border-radius);
    font-size: 12px;
}

.overdue
{
    background-color: var(--color-red-light);
    color: var(--color-text-dark);
    padding: 10px 20px;
    border-radius: var(--border-radius);
    font-size: 12px;
}


.toReceive
{
    background-color: var(--color-gray-light);
    color: var(--color-text-dark);
    padding: 10px 20px;
    border-radius: var(--border-radius);
    font-size: 12px;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        gap: 10px;
    }
    .infos{
        gap: 10px;
        justify-content: space-between;

    }
}