.container
{
    display: flex;
    flex:1;
    flex-direction: column;
}

.containerInfos
{
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.timer
{
   background-color: #E2FCD6;
   border-radius: var(--border-radius);
   padding-left: 16px;
   padding-right: 16px;
   padding-top: 6px;
   padding-bottom: 6px;
   font-size: 16px;
}