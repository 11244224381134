.mainContainer
{
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
}

.titleContainer
{
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}

.areaContainer
{
    display: flex;
    flex:1;
    flex-direction: column;
    min-width: 400px;
    border-radius: var(--border-radius);
    background-color: white;
    padding: 40px;
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
    gap: 16px;
}

@media (max-width: 1010px) {
    .areaContainer
    {
        flex-direction: column;
        min-width: 100%;
        width: 100%;
        padding: 30px;
        box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
        gap: 16px;
    }
    
}