.templatesContainer
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: var(--color-gray-light);
    border-radius: var(--border-radius);
    padding: 10px;
    margin-bottom: 20px;
}