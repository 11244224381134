.patient-status-activated
{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-green-light);
}

.patient-status-incomplete
{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-red)
}

.patient-status-disabled
{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(236, 236, 236);
}