.patient-item {
    display: flex;
    flex-direction: row;
    padding: 12px;
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    border-color: white;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
    box-shadow: 0 0 10px rgba(196, 196, 196, 0.2);
    flex-wrap: wrap;
    /* Permite quebrar para a próxima linha em telas menores */
}

.patient-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    flex: 1;
    margin-left: 18px;
    margin-right: 60px;
}

.patient-item-text-name,
.patient-item-text-cpf,
.patient-item-text-phone {
    color: var(--color-text-dark);
}

.patient-item-text-name {
    flex: 1;
}

.patient-item-text-cpf,
.patient-item-text-phone {
    flex: 1;
}

.patient-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-right: 20px;
    flex-shrink: 0;
}

@media (max-width: 768px) {
   
    .patient-item {
        flex-direction: row;
    }
    .patient-buttons {
        margin-right: 10px;
        margin-top: 10px;
    }

    .patient-item-text-cpf,
    .patient-item-text-phone {
    display: none;
    }
}