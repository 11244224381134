.appointments-header-container
{
    display: flex;
    flex:1;
    flex-direction: row;
    gap: 30px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.appointments-header-buttons-container
{
    display: flex;
    align-items: center;
    gap: 10px;
}

@media (max-width: 700px) {
    .appointments-header-container {
        padding-inline: 20px;
    }
}