.appointmentsByDayContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.dayHeader {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 8px;
}

.dayTitle {
    color: var(--color-text-dark);
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0;
}

.appointmentsList {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.emptyState {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    background-color: var(--color-background-light);
    border-radius: 8px;
    color: var(--color-text-light);
    font-size: 0.9rem;
}

@media (max-width: 1010px) {
    .appointmentsByDayContainer {
        gap: 16px;
    }

    .dayHeader {
        padding: 0;
    }
}
