.dashboard-welcome {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    gap: 8px;
    padding: 0 16px;
}

.dashboard-welcome h2 {
    font-size: 2rem;
    color: var(--color-text);
    margin: 0;
}

.dashboard-welcome p {
    color: var(--color-text-light);
    margin: 0;
}

.dashboard-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 16px;
    margin-bottom: 32px;
}

.dashboard-top-section {
    width: 100%;
}

.dashboard-bottom-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
}

.dashboard-item-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: var(--border-radius);
    padding: 32px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out;
    min-width: 0;
}

.dashboard-item-container:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

/* Ajuste do espaçamento entre título e conteúdo */
.dashboard-item-container > :first-child {
    margin-bottom: 24px;
}

.dashboard-birthdays-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 12px;
    margin: 0;
}

.dashboard-birthdays-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-color: var(--color-background-light);
    border-radius: 8px;
    text-align: center;
    gap: 12px;
}

.empty-icon {
    color: var(--color-text-light);
    font-size: 48px;
}

.empty-text {
    color: var(--color-text-light);
    font-size: 0.9rem;
}

.dashboard-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    overflow: hidden;
    margin: 0;
}

@media (max-width: 1200px) {
    .dashboard-bottom-section {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .dashboard-content {
        padding: 0 12px;
        gap: 16px;
    }

    .dashboard-welcome {
        padding: 0 12px;
        margin-bottom: 24px;
    }

    .dashboard-welcome h2 {
        font-size: 1.75rem;
    }

    .dashboard-item-container {
        padding: 24px;
    }

    .dashboard-item-container > :first-child {
        margin-bottom: 20px;
    }

    .dashboard-bottom-section {
        gap: 16px;
    }
}

@media (max-width: 480px) {
    .dashboard-welcome h2 {
        font-size: 1.5rem;
    }

    .dashboard-item-container {
        padding: 20px;
    }

    .dashboard-item-container > :first-child {
        margin-bottom: 16px;
    }
}