.patientBirthDayContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--color-white);
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--color-border);
    transition: all 0.2s ease-in-out;
}

.patientBirthDayContainer:hover {
    border-color: var(--color-main);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.infoContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    min-width: 0;
}

.age {
    background-color: var(--color-main);
    color: var(--color-white);
    padding: 4px 8px;
    border-radius: var(--border-radius);
    font-weight: 600;
    font-size: 0.9rem;
    flex-shrink: 0;
}

.name {
    font-weight: 500;
    color: var(--color-text-dark);
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.containerActions {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    flex-shrink: 0;
}

.messageSentIcon {
    background-color: var(--color-success-light);
    color: var(--color-success);
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whatsappButton {
    color: var(--color-text-light) !important;
    transition: all 0.2s ease-in-out !important;
}

.whatsappButton:hover {
    color: var(--color-main) !important;
    background-color: transparent !important;
}