.container
{
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    height: 90vh;
    justify-content: center;
}

.containerLogin
{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
}

.containerInputs
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.containerPassword
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}

.logo
{
    width: 60px;
    height: 60px;
}

.buttonVisibility
{
    cursor: pointer;
}