.container
{
    display: flex;
    flex-direction: row;
    align-items: center;
    /*justify-content: center;*/
    gap: 30px;
    background-color: white;
    border-radius: var(--border-radius);
    padding-left: 40px;
    padding-right: 40px;
    height: 60px;
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}

@media (max-width: 768px) {
    .container {
        justify-content: center;
        gap: 20px;
    }
}