.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.actions {
    display: flex;
    align-items: center;
    gap: 20px;
}

.showInactive {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #666;
}

.categoriesContainer {
    display: flex;
    gap: 40px;
}

.categorySection {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.categorySection h3 {
    margin: 0;
    color: #333;
}

.inactiveRow {
    background-color: #f5f5f5;
    color: #666;
}

.inactiveRow td {
    color: #666;
}