.section {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sectionHeader {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    color: var(--color-text);
    font-weight: 500;
    border-radius: 8px;
    transition: background-color 0.2s;
}

.sectionHeader:hover {
    background-color: var(--color-background-hover);
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: var(--color-text-light);
}

.title {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 24px;
}