.day-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border-radius: var(--border-radius);
    gap: 8px;
    height: 35px;
    background-color: #eef0f4;
    padding: 0 16px;
    margin-bottom: 16px;
}

.day-header-disabled{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 8px;
    border-radius: var(--border-radius);
    height: 35px;
    padding: 0 16px;
    margin-bottom: 16px;
    opacity: 0.6;
}

.day-header-today{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border-radius: var(--border-radius);
    gap: 8px;
    height: 35px;
    background-color: var(--color-white);
    padding: 0 16px;
    margin-bottom: 16px;
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}

.dayOfWeek,
.dayOfWeek-active {
    color: var(--color-text-dark);
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    opacity: 0.9;
}

.day,
.day-active {
    color: var(--color-text-dark);
    font-size: 15px;
    font-weight: 500;
}

