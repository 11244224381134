
.primary
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: var(--color-main-dark);
    background-color: var(--color-main-light);
    border-radius: 50%;
}

.secondary
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: var(--color-secondary);
    background-color: var(--color-secondary-light);
    border-radius: 50%;
}

.none
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: var(--color-gray-dark);
    background-color: var(--color-gray-light);
    border-radius: 50%;
}

.alert
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    color: var(--color-red-light);
    background-color: var(--color-red);
    border-radius: 50%; 
}