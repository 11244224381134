.listContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;
}

.emptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-color: var(--color-background-light);
    border-radius: 8px;
    text-align: center;
    gap: 12px;
}

.emptyIcon {
    color: var(--color-text-light);
    font-size: 48px;
}

.emptyText {
    color: var(--color-text-light);
    font-size: 0.9rem;
}

@media (max-width: 1010px) {
    .listContainer {
        gap: 16px;
    }
}