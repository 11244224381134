.container
{
    display: flex;
    align-items: center;
    gap: 40px;
}

.logo
{   
    width: 100px;
    height: auto;
    align-self: center;
}

.infos
{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.info
{
    display: flex;
    gap: 10px;
    align-items: center;
}