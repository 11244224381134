.mainContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    border-radius: var(--border-radius);
    background-color: white;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}

.containerShortcuts{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
}
.containerDatePicker
{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.containerDatePickerAndShortcuts
{
    display: flex;
    flex:1;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}