.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.containerStats {
    display: flex;
    flex:1;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.containerAppointmentsList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}