.container
{
    display: flex;
    flex:1;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: var(--border-radius);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-gray-light);
}

.containerValues
{
    display: flex;
    flex:1;
    flex-direction: row;
    gap: 20px;
    align-items: center;

}

.icon
{
    font-size: 26px;
    color: var(--color-primary);
}

.label
{
    font-size: 16px;
    color: var(--color-text-dark);
}
.value 
{
    font-size: 20px;
    color: var(--color-text-dark);
}