.sidebar-opened {
  display: flex;
  width: 75px;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  background-color: var(--color-white);
  gap: 16px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.sidebar-expanded {
  width: 230px;
}

.sidebar-groups {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--color-white);
  padding: 8px 12px;
  gap: 4px;
}

.logo {
  margin-top: 16px;
  transition: transform 0.3s ease;
  width: 28px;
  height: 28px;
}

.sidebar-expanded .logo {
  transform: scale(1.15);
}

/* Estilização da barra de rolagem */
.sidebar-groups::-webkit-scrollbar {
  width: 4px;
}

.sidebar-groups::-webkit-scrollbar-track {
  background: var(--color-white);
}

.sidebar-groups::-webkit-scrollbar-thumb {
  background-color: var(--color-main-light);
  border-radius: 2px;
}

/* Esconder a barra de rolagem quando o sidebar não estiver expandido */
.sidebar-opened:not(.sidebar-expanded) .sidebar-groups::-webkit-scrollbar {
  display: none;
}