.containerTable
{
    background-color: white;
    border-radius: var(--border-radius);
    padding: 20px;
    margin-bottom: 10px;
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}

.table td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 60px;
    padding-left: 20px;
}

.table td {
    padding: 6px;
}

.table th:last-child {
   padding-left: 16px;
}
