.container {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: calc(100vh - 120px);
}

.chatList {
  width: 30%;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chatArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.messageContainer {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputArea {
  display: flex;
  padding: 16px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
  gap: 10px;
}

.backButton {
  display: none;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .container {
    position: relative;
  }

  .chatList {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    background: white;
    transition: transform 0.3s ease;
  }

  .chatArea {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: white;
  }

  .chatListHidden {
    transform: translateX(-100%);
  }

  .chatAreaHidden {
    transform: translateX(100%);
  }

  .backButton {
    display: block;
  }
}