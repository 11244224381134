.container
{
    display: flex;
    flex-direction: row;
    padding: 10px;
    color: white;
    cursor: pointer;
    background-color: var(--color-text-dark);
    border-radius: var(--border-radius);
    font-size: 12px;
}

.containerOff
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
}