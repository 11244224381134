.container
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.date
{
    border-radius: var(--border-radius);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-gray-light);
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: white;
    color:  var(--color-gray-dark);
    width: fit-content;
}

.dateActived
{
    border-radius: var(--border-radius);
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: var(--color-gray-dark);
    color:white;
    width: fit-content;
}