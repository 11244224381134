.mainContainer
{
    display: flex;
    flex:1;
    flex-direction: column;
    gap:20px;
}

.line
{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.item
{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer{
    display: flex;
    flex-direction: row;
    gap: 20px;
}