.current-hour-line
{
    position:absolute;
    width: calc(100% - 180px);
    border-top: 1px dashed  var(--color-gray-dark);
    background-color: #fff0;
    height: 1px;
    transition: all 1s ease-in-out;
}

.current-hour-bullet
{
    width: 50px;
    margin-top: -12px;
    border-radius: 100px;
    background-color: var(--color-gray-dark);
    color: var(--color-white);
    font-size: 12px;
    padding: 5px;
    padding-left: 10px;
}