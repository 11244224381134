.mainContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
    align-items: center;
    justify-items: center;
    padding: 30px;
    gap: 30px;
    background-color: var(--color-white);
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
}

.containerContent
{
    display: flex;
    flex: 1;
    flex-direction: row;
    gap:30px;
    align-items: center;
    justify-items: center;
}

.actions{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.patientName{
  min-width: 200px;
}