.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.containerResume
{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}

.containerTable
{
    background-color: white;
    border-radius: var(--border-radius);
    padding: 20px;
    margin-bottom: 10px;
    box-shadow: 0 0 20px rgba(196, 196, 196, 0.2);
    overflow: auto;
}


.containerColumns {
    display: flex;
    gap: 6px;
    flex: 1;
}

.column {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: var(--border-radius);
    padding: 20px; /* Menor padding */
    margin-bottom: 10px;
    box-shadow: 0 0 15px rgba(196, 196, 196, 0.2); /* Leve redução no shadow */
    gap: 10px; /* Menor gap entre cards */
}

.leadCard {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Garantir largura consistente */
    justify-content: space-between; /* Distribuir conteúdo */
    padding: 14px; /* Menor padding */
    border: 1px solid #e0e0e0;
    border-radius: var(--border-radius);
    margin-bottom: 5px; /* Redução na margem */
    height: 120px; /* Altura fixa para uniformizar */
    box-sizing: border-box; /* Incluir padding na altura total */
}

.leadHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px; /* Reduzir espaço */
}

.leadDate {
    font-size: 0.8em; /* Reduzir texto */
    color: #888;
}

.leadName {
    display: flex;
    align-items: center;
    gap:6px;
    font-size: 0.9em; /* Reduzir texto */
    font-weight: bold;
}

.leadSecondaryInfo {
    color: #555;
    display: flex;
    flex-direction: row;
    gap: 5px; /* Menor espaço entre itens */
    font-size: 0.8em; /* Reduzir texto */
    align-items: center;
}

.leadTags {
    display: flex;
    gap: 3px; /* Diminuir espaço entre tags */
    flex-wrap: wrap; /* Quebrar linha se necessário */
}

.leadTag {
    background-color: #ffffff;
    border-radius: 3px;
    padding: 1px 4px; /* Menor padding */
    font-size: 0.7em; /* Reduzir texto */
    border: 1px solid #ccc; /* Adicionar borda leve */
}

.leadActions {
    display: flex;
    justify-content: space-between;
    gap: 5px; /* Menor gap */
}

.hotLead {
    background-color: rgba(255, 99, 71, 0.1); /* Tom vermelho */
    border: 1px solid rgba(255, 99, 71, 0.3);
}

.warmLead {
    background-color: rgba(255, 165, 0, 0.1); /* Tom laranja */
    border: 1px solid rgba(255, 165, 0, 0.3);
}

.coldLead {
    background-color: rgba(173, 216, 230, 0.1); /* Tom azul */
    border: 1px solid rgba(173, 216, 230, 0.3);
}

.successLead {
    background-color: rgba(182, 243, 182, 0.1); /* Tom verde */
    border: 0px solid rgba(50, 205, 50, 0.3);
}


.failureLead {
    background-color: rgba(252, 205, 211, 0.5); /* Tom vermelho */
    border: 0px solid rgba(255, 0, 0, 0.3);
}

.failureInactiveLead
{
    background-color: rgba(252, 205, 211, 0.1); /* Tom vermelho */
    border: 0px solid rgba(255, 0, 0, 0.3);
}