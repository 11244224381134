.container
{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    border-radius: var(--border-radius);
    background-color: var(--color-white);
}

.containerInfos
{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    gap: 30px;
}

.containerAppointment
{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.containerEvolution
{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.containerEvolutionItem
{
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 350px;
    gap: 10px;
    background-color: #f9f9f9;
    border-radius: var(--border-radius );
    padding: 20px;
}

.footer
{
    display: flex;
    flex-direction: row;
    gap: 20px;
}


.error
{
    border-radius: var(--border-radius);
    border-color:  var(--color-red);
    border-style: solid;
    border-width: 1px;
    color: var(--color-red);
    padding: 10px 20px;
    margin-top: 10px;
}