.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    margin-bottom: 8px;
}

.sectionHeader {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    color: var(--color-text-light);
    font-weight: 600;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    transition: all 0.2s;
    position: relative;
    margin-top: 4px;
    opacity: 0.8;
}

.sectionHeaderCollapsed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    cursor: pointer;
    color: var(--color-text-light);
    transition: all 0.2s;
    margin-top: 4px;
    opacity: 0.8;
    border-radius: var(--border-radius);
}

.sectionHeaderActive {
    background-color: var(--color-main);
    color: white;
    opacity: 1;
}

.sectionHeader:hover, 
.sectionHeaderCollapsed:hover {
    background-color: var(--color-background-hover);
    opacity: 1;
}

.sectionHeaderActive:hover {
    background-color: var(--color-main);
}

.sectionIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 22px;
    color: inherit;
    transition: all 0.2s;
}

.sectionIconActive {
    color: white;
    opacity: 1;
}

.sectionHeader:hover .sectionIcon,
.sectionHeaderCollapsed:hover .sectionIcon {
    color: var(--color-main);
}

.sectionHeaderActive:hover .sectionIcon {
    color: white;
}

.title {
    margin-left: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
}

.chevron {
    width: 12px;
    height: 12px;
    color: inherit;
    margin-left: 8px;
    opacity: 0.7;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-left: 40px;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    max-height: 500px;
}

.sectionContent.collapsed {
    max-height: 0;
}