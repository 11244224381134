:root {
  /* Cores base */
  --color-white: #fff;
  --color-black: #000;

  /* Cores principais */
  --color-main: #206afa;
  --color-primary: #206afa;
  --color-main-dark: #385AA4;
  --color-main-light: #aed4f5;
  
  /* Cores secundárias */
  --color-secondary: #ed3680;
  --color-secondary-light: #ffdfec;
  
  /* Estados e feedback */
  --color-red: #e74c3c;
  --color-red-light: #f08070;
  --color-green-dark: #B9C4B0;
  --color-green-light: #E2FCD6;
  
  /* Texto */
  --color-text-dark: #273644;
  --color-text-light: #535353;
  --color-text-light2: #a3a3a3;
  
  /* Backgrounds e tons de cinza */
  --color-gray-light: #f7f7f7;
  --color-gray-dark: #242424;
  --color-background-dark: #242424;
  --color-background-light: #f7f7f7;

  /* Variações alpha */
  --color-border: rgba(0, 0, 0, 0.1);
  --color-shadow: rgba(0, 0, 0, 0.1);
  --color-main-alpha: rgba(32, 106, 250, 0.1);
}